import { gql } from "apollo-boost";
export const SIGNUP_ADMIN = gql`
  mutation AdminSingUp($name: String, $email: String, $password: String) {
    adminSingUp(
      adminSignUpInput: { name: $name, email: $email, password: $password }
    ) {
      id
      name
      email
      token
    }
  }
`;

export const FORGOT_ADMIN = gql`
  mutation forgotPassword($email: String) {
    forgotPassword(email: $email)
  }
`;

export const SET_USER_STATUS = gql`
  mutation setUserStatus($id: [Int], $status: userStatus) {
    setUserStatus(id: $id, status: $status)
  }
`;
