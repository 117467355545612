import { GET_ALL } from "../actions/actionTypes";

const initialState = {
  getAllUser: [],
  count: 0,
};

const userTableReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL: {
      return {
        ...state,
        getAllUser: action.payload,
        count: action.count,
        loading: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default userTableReducer;
