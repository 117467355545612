import React, { Component } from "react";
import {
  Card,
  Grid,
  Button,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { loginAdmin } from "../../redux/actions";
import history from "../../../history";

import "./Login.scss";
import Alert from "@material-ui/lab/Alert";

const styles = (theme) => ({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "70%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class LogIn extends Component {
  state = {
    email: "",
    password: "",
    errorAlert: false,
    errorMsg: [],
    showLoader: false,
  };

  componentDidMount = () => {
    if (localStorage.getItem("auth_Token") !== null) {
      history.push("/");
    }
  };
  showLoader = () => {
    this.setState({
      showLoader: true,
    });
  };
  hideLoader = () => {
    this.setState({
      showLoader: false,
    });
  };
  handleChange = (event) => {
    this.setState({
      errorAlert: false,
      [event.target.name]: event.target.value,
    });
  };

  handleFormSubmit = async (event) => {
    this.showLoader();
    let { client } = this.props;
    let { email, password } = this.state;
    const res = await loginAdmin(client, email, password);

    if (res.error) {
      this.setState({
        errorMsg: res.error,
        errorAlert: true,
      });
      this.hideLoader();
    } else {
      let userdata = res.data;
      if (userdata) {
        localStorage.setItem("auth_Token", userdata.token);
        this.timer = setInterval(() => {
          history.push("/");
        }, 100);
      } else {
        localStorage.setItem("auth_Token", null);
      }
      this.hideLoader();
    }
  };
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    let { email, password, errorAlert, errorMsg, showLoader } = this.state;
    let { classes } = this.props;
    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8 box-width">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="p-36 h-100 bg-light-gray position-relative">
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <div className="error-alert">
                      {errorAlert === true ? (
                        <Alert variant="filled" severity="error">
                          {errorMsg}
                        </Alert>
                      ) : (
                        " "
                      )}
                    </div>

                    <div className="login-title">Login</div>
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "This field is required.",
                        "Email is not valid.",
                      ]}
                    />
                    <TextValidator
                      className="mb-16 w-100"
                      label="Password"
                      variant="outlined"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={password}
                      validators={["required"]}
                      errorMessages={["This field is required."]}
                    />
                    <div className="flex flex-middle mb-8">
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={showLoader}
                          type="submit"
                        >
                          Log In
                        </Button>
                        {showLoader && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                    <Button
                      className="text-primary"
                      onClick={() =>
                        this.props.history.push("/forgot-password")
                      }
                    >
                      Forgot password?
                    </Button>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}
export default withApollo(
  withStyles(styles, { withTheme: true })(withRouter(LogIn))
);
