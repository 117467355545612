import { gql } from "apollo-boost";

export const GET_ALL_USERS = (newsearch, limit, skip) => gql`
  query {       
    getUsers(search: ${newsearch},limit: ${limit}, skip: ${skip}) 
    {
      UserList{
        id
        name
        email
        college
        totalFriend
        messageSend
        messageRecieved
        status
        pictures
      }
      totalCount
    }
  }
`;
export const LOGIN_ADMIN = (email, password) => gql`
query {
    login(email: "${email}", password: "${password}") 
    {
      id
      name
      email
      token
    }
  }
`;
