import {
  UPDATE_USER_PROFILE,
  REMOVE_COURSES,
  ACTIVE_COURSES,
  HIDE_IMAGES,
  ACTIVE_IMAGES,
  ACTIVATE_MATCHES,
  REMOVE_MATCHES,
  BLOCK_MATCHES,
} from "../Mutation";
import {
  GET_USER_PROFILE,
  GET_USER_IMAGES,
  GET_USER_COURSES,
  GET_ACTIVE_MATCHES,
  GET_HISTORICAL_MATCHES,
  GET_BLOCKED_MATCHES,
  GET_OTHERS_REPORTED_AGAINST,
  GET_OTHERS_INITIATED,
  // GET_CHAT_ROOM,
  GET_ALL_UNIVERSITY_DOMAIN,
  GET_CHAT,
} from "../Queries";
import {
  GET_PROFILE,
  GET_IMAGES,
  GET_COURSES,
  GET_ACTIVE,
  GET_HISTORICAL,
  GET_BLOCKED,
  GET_REPORTED_AGAINST,
  GET_INITIATED,
  GET_UNIVERSITY_DOMAIN,
  USER_CHAT,
} from "./actionTypes";

import { sessionExpire } from "./UserActions";

export const getUserProfile = (client, id) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_USER_PROFILE(id),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          returnData = {
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          };
        } else {
          dispatch({
            type: GET_PROFILE,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getUserProfile,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};

export const getUserImages = (client, id, type) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_USER_IMAGES(id, type),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          returnData = {
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          };
        } else {
          dispatch({
            type: GET_IMAGES,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getUserImages,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};

export const getUserCourses = (client, id, type) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_USER_COURSES(id, type),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          returnData = {
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          };
        } else {
          dispatch({
            type: GET_COURSES,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getUserCourses,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};

export const getActiveMatches = (client, id) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_ACTIVE_MATCHES(id),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          dispatch({
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          });
        } else {
          dispatch({
            type: GET_ACTIVE,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getActiveMatches,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};

export const getHistoricalMatches = (client, id) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_HISTORICAL_MATCHES(id),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          returnData = {
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          };
        } else {
          dispatch({
            type: GET_HISTORICAL,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getHistoricalMatches,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};

export const getBlockedMatches = (client, id) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_BLOCKED_MATCHES(id),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          returnData = {
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          };
        } else {
          dispatch({
            type: GET_BLOCKED,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getBlockedMatches,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};

export const getOthersReportedAgainst = (client, id) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_OTHERS_REPORTED_AGAINST(id),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          returnData = {
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          };
        } else {
          dispatch({
            type: GET_REPORTED_AGAINST,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getOthersReportedAgainst,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};

export const getOthersInitiated = (client, id) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_OTHERS_INITIATED(id),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          returnData = {
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          };
        } else {
          dispatch({
            type: GET_INITIATED,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getOthersInitiated,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};

export const getChat = (client, id, Sid) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_CHAT(id, Sid),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          returnData = {
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          };
        } else {
          dispatch({
            type: USER_CHAT,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getChat,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};

export async function editUserProfile(client, data) {
  let returnData = "";
  await client
    .mutate({
      mutation: UPDATE_USER_PROFILE,
      variables: {
        UpdateInput: {
          id: data.id,
          update: {
            name: data.name,
            email: data.email,
            college: data.college,
            twitter: data.twitter,
            instagram: data.instagram,
            snapchat: data.snapchat,
          },
        },
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        sessionExpire(data);
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}

export async function removeUsersCourses(client, data) {
  let returnData = "";
  await client
    .mutate({
      mutation: REMOVE_COURSES,
      variables: {
        id: data.id,
        userId: data.userId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        sessionExpire(data);
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}

export async function activeUsersCourses(client, data) {
  let returnData = "";
  await client
    .mutate({
      mutation: ACTIVE_COURSES,
      variables: {
        id: data.id,
        userId: data.userId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        sessionExpire(data);
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}

export async function hideUsersImages(client, data) {
  let returnData = "";
  await client
    .mutate({
      mutation: HIDE_IMAGES,
      variables: {
        id: data.id,
        img: data.img,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        sessionExpire(data);
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}

export async function activeUsersImages(client, data) {
  let returnData = "";
  await client
    .mutate({
      mutation: ACTIVE_IMAGES,
      variables: {
        id: data.id,
        img: data.img,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        sessionExpire(data);
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}

export async function activeUsersMatches(client, data) {
  let returnData = "";
  await client
    .mutate({
      mutation: ACTIVATE_MATCHES,
      variables: {
        id: data.id,
        friendId: data.friendId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        sessionExpire(data);
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}

export async function removeUsersMatches(client, data) {
  let returnData = "";
  await client
    .mutate({
      mutation: REMOVE_MATCHES,
      variables: {
        id: data.id,
        friendId: data.friendId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        sessionExpire(data);
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}

export async function blockUsersMatches(client, data) {
  let returnData = "";
  await client
    .mutate({
      mutation: BLOCK_MATCHES,
      variables: {
        id: data.id,
        friendId: data.friendId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        sessionExpire(data);
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}

export const getAllUniversityDomain = (client, searchText, limit, skip) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_ALL_UNIVERSITY_DOMAIN(searchText, limit, skip),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          returnData = {
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          };
        } else {
          dispatch({
            type: GET_UNIVERSITY_DOMAIN,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getAllUniversityDomain,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};
