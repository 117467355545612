import history from "history.js";
import { GET_ALL_USERS, LOGIN_ADMIN } from "../Queries";
import { SIGNUP_ADMIN, SET_USER_STATUS, FORGOT_ADMIN } from "../Mutation";

import { GET_ALL } from "./actionTypes";
export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export function setUserData(user) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
}

export function sessionExpire(data) {
  let errorMsg = data.errors[0].message;
  if (errorMsg === "Unauthorized") {
    localStorage.removeItem("auth_Token");
    history.push({
      pathname: "/login",
    });
  }
}

export function logoutUser() {
  return (dispatch) => {
    localStorage.removeItem("auth_Token");
    history.push({
      pathname: "/login",
    });
    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}

export async function signupAdmin(client, data) {
  let returnData = "";
  await client
    .mutate({
      mutation: SIGNUP_ADMIN,
      variables: {
        name: data.name,
        email: data.email,
        password: data.password,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}

export async function forgotAdmin(client, email) {
  let returnData = "";
  await client
    .mutate({
      mutation: FORGOT_ADMIN,
      variables: {
        email: email.email,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        sessionExpire(data);
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}

export async function loginAdmin(client, email, password) {
  let returnData = "";
  await client
    .query({
      query: LOGIN_ADMIN(email, password),
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data.data.login,
        };
      }
    })
    .catch((error) => {
      returnData = error;
    });
  return returnData;
}

export const fetchUserData = (client, newsearch, limit, skip) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_ALL_USERS(newsearch, limit, skip),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          dispatch({
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          });
        } else {
          dispatch({
            type: GET_ALL,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getUsers.UserList,
            count: data.data.getUsers.totalCount,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};

export async function setUserStatus(client, id, status) {
  let returnData = "";
  await client
    .mutate({
      mutation: SET_USER_STATUS,
      variables: {
        id: id,
        status,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        sessionExpire(data);
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}
