import React, { Component } from "react";
import {
  Card,
  Grid,
  Button,
  CircularProgress,
  withStyles,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import { forgotAdmin } from "../../redux/actions";
import { withApollo } from "react-apollo";
import history from "history.js";
import "./ForgotPassword.scss";
import Alert from "@material-ui/lab/Alert";

const styles = (theme) => ({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});
class ForgotPassword extends Component {
  state = {
    email: "",
    errorAlert: false,
    successAlert: false,
    resError: false,
    successMsg: "",
    errorMsg: [],
    showLoader: false,
  };

  componentDidMount = () => {
    if (localStorage.getItem("auth_Token") !== null) {
      this.props.history.push("/");
    }
  };
  showLoader = () => {
    this.setState({
      showLoader: true,
    });
  };
  hideLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      errorAlert: false,
      successAlert: false,
      [event.target.name]: event.target.value,
    });
  };
  handleFormSubmit = async (event) => {
    this.showLoader();
    let { client } = this.props;
    let email = this.state;
    const res = await forgotAdmin(client, email);
    if (res.error) {
      this.setState(
        {
          errorMsg: res.error,
          errorAlert: true,
          resError: true,
        },
        () => {
          this.hideLoader();
        }
      );
    } else {
      let forgotData = res.data;
      if (forgotData) {
        this.setState(
          {
            successMsg: res.data.data.forgotPassword,
            successAlert: true,
            resError: false,
          },
          () => {
            this.hideLoader();
            window.setTimeout(() => {
              history.push({
                pathname: "/login",
              });
            }, 3000);
          }
        );
      }
      //
    }
  };
  render() {
    const {
      email,
      resError,
      errorAlert,
      errorMsg,
      successAlert,
      successMsg,
      showLoader,
    } = this.state;

    let { classes } = this.props;

    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="p-36 h-100 bg-light-gray position-relative">
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    {resError === true ? (
                      <div className="alert">
                        {errorAlert === true ? (
                          <Alert variant="filled" severity="error">
                            {errorMsg}
                          </Alert>
                        ) : (
                          " "
                        )}
                      </div>
                    ) : (
                      <div className="alert">
                        {successAlert === true ? (
                          <Alert variant="filled" severity="success">
                            {successMsg}
                          </Alert>
                        ) : (
                          " "
                        )}
                      </div>
                    )}

                    <div className="forgot-title">Forgot Password</div>
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "this field is required",
                        "email is not valid",
                      ]}
                    />

                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={showLoader}
                        type="submit"
                      >
                        Reset Password
                      </Button>
                      {showLoader && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    <div className="p-t10">
                      <Button
                        className="capitalize btn-width"
                        onClick={() => this.props.history.push("/login")}
                      >
                        Log In
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

export default withApollo(
  withStyles(styles, { withTheme: true })(withRouter(ForgotPassword))
);
