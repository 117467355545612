import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
// import Loader from "../../Reusable/Loader";
import routes from "../../InnerRoutes";
import NotFound from "../../views/Authentication/NotFound";
class Content extends Component {
  render() {
    return (
      <div id="page-wrapper">
        {/* <Suspense fallback={<Loader />}> */}
        <Suspense fallback>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                strict
                render={() => <route.component {...this.props} />}
              />
            ))}
            <Route
              render={() => (
                // <Suspense fallback={<Loader />}>
                <Suspense fallback>
                  <NotFound />
                </Suspense>
              )}
            />
          </Switch>
        </Suspense>
      </div>
    );
  }
}
export default Content;
