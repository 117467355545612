import { gql } from "apollo-boost";
export const GET_USER_PROFILE = (id) => gql`
query{
    getUserProfile(id:${id})
    {
       id
       name
       email
       userStatus
       mainImage
       image
       major 
       about
       interest
       {
         id
         interest_name
       }
       highSchool
       college
       exceptedGraduation
       instagram
       twitter
       snapchat
       courses
       createdAt
       last_activeAt
       flag
    }
}
`;
export const GET_USER_IMAGES = (id, type) => gql`
query{
  getUserImages(id: ${id}, type: "${type}")
  {
    imgPath
    date
  }   
}
`;
export const GET_USER_COURSES = (id, type) => gql`
query{
  getUserCourses(id: ${id}, type: "${type}")
  {
    id
    name
    subject
    professor
    days
    dateOfMatched
    totalStudents
  }   
}
`;
export const GET_ACTIVE_MATCHES = (id) => gql`
query {
  getActiveMatches(id: ${id}) {
    id
    name
    image
    matchedAt
    totalMessages

  }
}
`;
export const GET_HISTORICAL_MATCHES = (id) => gql`
query {
  getHistoricalMatches(id: ${id}) {
    id
    name
    image
    matchedAt
    totalMessages
  }
}
`;
export const GET_BLOCKED_MATCHES = (id) => gql`
query {
  getBlockedMatches(id: ${id}) {
    id
    name
    image
    matchedAt
    totalMessages
  }
}
`;
export const GET_OTHERS_REPORTED_AGAINST = (id) => gql`
  query {
    getOthersReportedAgainst(id: ${id}) {
      id
      reportedBy
      reportedUser
      dateOfReported
      status
      type
      dateOfResolved
    }
  }
`;
export const GET_OTHERS_INITIATED = (id) => gql`
  query {
    getOthersInitiated(id: ${id}) {
      id
      reportedBy
      reportedUser
      dateOfReported
      status
      type
      dateOfReported
    }
  }
`;
export const GET_CHAT_ROOM = (id, friendId) => gql`
  query {
    getChatRoom(id: ${id},friendId: ${friendId}) 
  }
`;
export const GET_CHAT = (id, Sid) => gql`
  query {
    getChat(id: ${id},Sid: ${Sid})
    {
      image
      name 
      message
      createdAt
    } 
  }
`;
export const GET_ALL_UNIVERSITY_DOMAIN = (searchText, limit, skip) => gql`
  query {
    getAllUniversityDomain(searchText: "${searchText}", limit: ${limit}, skip: ${skip}) {
      id
      collegeName
      domain
    }
  }
`;
