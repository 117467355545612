import { GET_ALL_INCIDENT, GET_INCIDENT_DATA } from "../Queries";
import { UPDATE_USER_REPORT } from "../Mutation";
import { sessionExpire } from "./UserActions";
import { GET_INCIDENTS, GET_INCIDENTS_DETAILS } from "../actions/actionTypes";
export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const getIncidentData = (
  client,
  incidentType,
  searchText,
  limit,
  skip
) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_ALL_INCIDENT(searchText, incidentType, limit, skip),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          dispatch({
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          });
        } else {
          dispatch({
            type: GET_INCIDENTS,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getReports.reportList,
            count: data.data.getReports.totalCount,
          });
        }
      })
      .catch((error) => {
        returnData = error;
      });
    return returnData;
  };
};

export const getReportDetails = (client, id) => {
  return async (dispatch) => {
    let returnData = "";
    await client
      .query({
        query: GET_INCIDENT_DATA(id),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      .then((data) => {
        if (data.errors) {
          sessionExpire(data);
          returnData = {
            isError: true,
            status: 404,
            error: data.errors[0].message,
            data: [],
          };
        } else {
          dispatch({
            type: GET_INCIDENTS_DETAILS,
            isError: false,
            status: 200,
            error: "",
            payload: data.data.getReportsById,
          });
        }
      })
      .catch((error) => {
        dispatch({
          isError: true,
          status: 500,
          error: error,
          data: [],
        });
      });
    return returnData;
  };
};

export async function editUserReport(client, data) {
  let returnData = "";
  await client
    .mutate({
      mutation: UPDATE_USER_REPORT,
      variables: {
        UpdateReport: {
          id: data.id,
          update: {
            status: data.status,
            type: data.type,
            description: data.description,
            moderator_notes: data.moderator_notes,
          },
        },
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    .then((data) => {
      if (data.errors) {
        sessionExpire(data);
        returnData = {
          isError: true,
          status: 404,
          error: data.errors[0].message,
          data: [],
        };
      } else {
        returnData = {
          isError: false,
          status: 200,
          error: "",
          data: data,
        };
      }
    })
    .catch((error) => {
      returnData = {
        isError: true,
        status: 500,
        error: error,
        data: [],
      };
    });
  return returnData;
}
