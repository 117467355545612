import { GET_INCIDENTS, GET_INCIDENTS_DETAILS } from "../actions/actionTypes";

const initialState = {
  getIncidentData: [],
  count: 0,
  getIncidentsDetails: {},
};

const incidentsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_INCIDENTS: {
      return {
        ...state,
        getIncidentData: action.payload,
        count: action.count,
      };
    }
    case GET_INCIDENTS_DETAILS: {
      return {
        ...state,
        getIncidentsDetails: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default incidentsReducer;
