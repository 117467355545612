import { ApolloClient, InMemoryCache } from "apollo-boost";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import moment from "moment";
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("auth_Token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const httpLink = createHttpLink({
  //live grphql server url
  uri: "http://35.173.95.216:9000/admin",
});
export default {
  // config: {
  //   apiUrl: "",
  // },
  errors: {
    error400: "Technical error!",
  },
  dateFormat: "MMM DD, YYYY h:mm a",
  getToken: () => {
    return localStorage.getItem("auth_Token");
  },
  getClient: () => {
    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  },
  convertDate: (date, format) => {
    let formattedDate = moment(date).format(format);
    return formattedDate;
  },
  capitalized: (string) => {
    return string.charAt(0).toUpperCase() + string.substring(1);
  },
};
