import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles, MuiThemeProvider } from "@material-ui/core";
import { connect } from "react-redux";
import {
  setLayoutSettings,
  setDefaultSettings,
} from "app/redux/actions/LayoutActions";
import { logoutUser } from "app/redux/actions/UserActions";
import { NavLink, withRouter } from "react-router-dom";
import Sidenav from "../SharedCompoents/Sidenav";
import SidenavTheme from "../EgretTheme/SidenavTheme";
import { isMdScreen } from "utils";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Layout1Sidenav.scss";
import incident from "../../../assets/icons/incidents.png";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

class Layout1Sidenav extends Component {
  state = {
    sidenavToggleChecked: false,
    hidden: true,
    expanded: false,
    sidebarOptions: [
      {
        displayName: "Incidents",
        key: 1,
        haveChild: true,
        icon: incident,
        link: "/incidents",
        panel: "panel1",
        subItems: [
          {
            displayName: "Unresolved",
            key: 1,
            isActive: false,
            link: "/incidents/unresolved",
            icon: "U",
          },
          {
            displayName: "Resolved",
            key: 2,
            isActive: false,
            link: "/incidents/resolved",
            icon: "R",
          },
        ],
      },
      {
        displayName: "Users",
        key: 2,
        panel: "panel2",
        isExpanded: false,
        haveChild: false,
        icon: incident,
        link: "/users",
      },
      // {
      //   displayName: "Admin",
      //   key: 3,
      //   panel: "panel3",
      //   isExpanded: false,
      //   haveChild: true,
      //   icon: incident,
      //   link: "/admin",
      //   subItems: [
      //     {
      //       displayName: "Menu-1",
      //       key: 1,
      //       isActive: false,
      //       link: "/incidents/unresolved",
      //       icon: "M",
      //     },
      //     {
      //       displayName: "Menu-2",
      //       key: 2,
      //       isActive: false,
      //       link: "/incidents/resolved",
      //       icon: "M",
      //     },
      //   ],
      // },
    ],
  };

  componentDidMount() {
    // CLOSE SIDENAV ON ROUTE CHANGE ON MOBILE
    this.unlistenRouteChange = this.props.history.listen((location, action) => {
      if (isMdScreen()) {
        this.updateSidebarMode({ mode: "close" });
      }
    });
    this.timer = setInterval(() => this.setState({ hidden: false }), 400);
  }

  componentWillUnmount() {
    this.unlistenRouteChange();
    clearInterval(this.timer);
  }

  updateSidebarMode = (sidebarSettings) => {
    let { settings, setLayoutSettings, setDefaultSettings } = this.props;
    const updatedSettings = {
      ...settings,
      layout1Settings: {
        ...settings.layout1Settings,
        leftSidebar: {
          ...settings.layout1Settings.leftSidebar,
          ...sidebarSettings,
        },
      },
    };
    setLayoutSettings(updatedSettings);
    setDefaultSettings(updatedSettings);
  };

  handleSidenavToggle = () => {
    let { sidenavToggleChecked } = this.state;
    let mode = sidenavToggleChecked ? "full" : "compact";
    this.updateSidebarMode({ mode });
    this.setState({ sidenavToggleChecked: !sidenavToggleChecked });
  };

  handleChange = (panel) => (event, expanded) => {
    if (expanded) {
      this.setState({ expanded: panel });
    } else {
      this.setState({ expanded: false });
    }
  };

  renderUser = () => {
    const { expanded, sidebarOptions } = this.state;
    // let { user } = this.props;
    const currentPath = this.props.location.pathname;

    return (
      <div className="sidenav-1">
        <div>
          <h1 className="sidebar-header">Afious</h1>
        </div>
        <div>
          {sidebarOptions.map((data, index) => {
            return data.haveChild ? (
              <div key={index}>
                <Accordion
                  className={
                    currentPath === data.link
                      ? "active-item"
                      : "MuiAccordion-root-46 MuiPaper-root-44 MuiPaper-elevation1-43"
                  }
                  // className=""
                  expanded={expanded === data.panel}
                  onChange={this.handleChange(data.panel)}
                >
                  <AccordionSummary
                    className="min-w48"
                    expandIcon={data.haveChild ? <ExpandMoreIcon /> : ""}
                    // className="Mui-expanded"
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <img
                      className="users-icon"
                      src={data.icon}
                      alt="incidents"
                    />
                    <Typography className="heading-name">
                      {data.displayName}
                    </Typography>
                  </AccordionSummary>
                  {data.haveChild
                    ? data.subItems.map((item, index) => {
                        return (
                          <NavLink
                            key={index}
                            to={item.link}
                            // onClick={() => this.handleClick("Unresolved")}
                          >
                            <AccordionDetails
                              className={
                                currentPath === item.link
                                  ? "active-item"
                                  : "MuiAccordionDetails-root"
                              }
                            >
                              <div style={{ display: "flex" }}>
                                <Typography className="text-icon">
                                  {item.icon}
                                </Typography>
                                <Typography className="heading-name pd-30">
                                  {item.displayName}
                                </Typography>
                              </div>
                            </AccordionDetails>
                          </NavLink>
                        );
                      })
                    : ""}
                </Accordion>
              </div>
            ) : (
              <NavLink to={data.link} key={index}>
                <Accordion
                  className={
                    currentPath === data.link
                      ? "active-item"
                      : "MuiAccordion-root-46 MuiPaper-root-44 MuiPaper-elevation1-43"
                  }
                  // className=""
                  expanded={data.haveChild ? expanded === data.panel : ""}
                  onChange={this.handleChange(data.panel)}
                >
                  <AccordionSummary
                    className="min-w48"
                    expandIcon={data.haveChild ? <ExpandMoreIcon /> : ""}
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <img
                      className="users-icon"
                      src={data.icon}
                      alt="incidents"
                    />
                    <Typography className="heading-name">
                      {data.displayName}
                    </Typography>
                  </AccordionSummary>
                  {data.haveChild
                    ? data.subItems.map((item, index) => {
                        return (
                          <NavLink
                            key={index}
                            to={item.link}
                            // onClick={() => this.handleClick("Unresolved")}
                          >
                            <AccordionDetails
                              className={
                                currentPath === item.link
                                  ? "active-item"
                                  : "MuiAccordionDetails-root"
                              }
                            >
                              <div style={{ display: "flex" }}>
                                <Typography className="text-icon">
                                  {item.icon}
                                </Typography>
                                <Typography className="heading-name pd-30">
                                  {item.displayName}
                                </Typography>
                              </div>
                            </AccordionDetails>
                          </NavLink>
                        );
                      })
                    : ""}
                </Accordion>
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    let { theme, settings } = this.props;
    const sidenavTheme =
      settings.themes[settings.layout1Settings.leftSidebar.theme] || theme;
    return (
      <MuiThemeProvider theme={sidenavTheme}>
        <SidenavTheme theme={sidenavTheme} settings={settings} />
        <div className="sidenav">
          <div className="sidenav__hold">
            {!this.state.hidden && (
              <Fragment>
                <Sidenav>{this.renderUser()}</Sidenav>
              </Fragment>
            )}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

Layout1Sidenav.propTypes = {
  setLayoutSettings: PropTypes.func.isRequired,
  setDefaultSettings: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  setDefaultSettings: PropTypes.func.isRequired,
  setLayoutSettings: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  user: state.user,
  settings: state.layout.settings,
});

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      setLayoutSettings,
      setDefaultSettings,
      logoutUser,
    })(Layout1Sidenav)
  )
);
