import React, { Fragment } from "react";
import { Icon } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const Breadcrumb = ({ routeSegments }) => {
  return (
    <div className="flex flex-middle position-relative margin-left">
      {routeSegments
        ? routeSegments.map((route, index) => (
            <Fragment key={index}>
              {index > 0 ? (
                <Icon className="text-hint">navigate_next</Icon>
              ) : (
                ""
              )}
              {index !== routeSegments.length - 1 ? (
                <NavLink to={route.path}>
                  <span className="capitalize text-muted">{route.name}</span>
                </NavLink>
              ) : (
                <span className="capitalize text-muted">{route.name}</span>
              )}
            </Fragment>
          ))
        : null}
    </div>
  );
};

export default Breadcrumb;
