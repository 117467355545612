import { gql } from "apollo-boost";
export const GET_ALL_INCIDENT = (searchText, incidentType, limit, skip) => gql`
  query {
       
    getReports(search: ${searchText},status:"${incidentType}",limit: ${limit}, skip: ${skip}) 
    {
      reportList{
        id
        dateResolved
        dateSubmitted
        name
        email
        college
        totalReport
      }
      totalCount
      
    }
  }
`;

export const GET_INCIDENT_DATA = (id) => gql`
query{
  getReportsById(id:${id})
    {
      id
      image
      type
      date
      reportedUser
      reportedUserId
      reportedBy
      prevReportedIncidents
      incident_details
      moderator_notes
      date
      status
    }
}`;
