import { gql } from "apollo-boost";

export const UPDATE_USER_REPORT = gql`
  mutation updateReport($UpdateReport: UpdateReport) {
    updateReport(UpdateReport: $UpdateReport)
  }
`;
export const UPDATE_REPORT_STATUS = gql`
  mutation updateReportStatus($id: ID, $status: String) {
    updateReportStatus(id: $id, status: $status)
  }
`;
