import "../styles/_app.scss";
import React, { Component } from "react";
import { Provider } from "react-redux";
import EgretTheme from "./EgretLayout/EgretTheme/EgretTheme";
import AppContext from "./appContext";

import { Store } from "./redux/Store";
import Routes from "./Routes";
class App extends Component {
  render() {
    return (
      <AppContext.Provider>
        <Provider store={Store}>
          <EgretTheme>
            <Routes />
          </EgretTheme>
        </Provider>
      </AppContext.Provider>
    );
  }
}
export default App;
