import React from "react";
import { Helmet } from "react-helmet";

const SidenavTheme = ({ theme, settings }) => {
  function darkHoverStyle() {
    return theme.palette.type === "dark"
      ? `.navigation .nav-item:hover,
        .navigation .nav-item.active {
          color: ${theme.palette.text.primary};
        }`
      : "";
  }

  function lightHoverStyle() {
    return theme.palette.type === "light"
      ? `.navigation .nav-item:hover,
        .navigation .nav-item.active,
        .navigation .submenu {
          background: rgba(0, 0, 0, .08);
        }`
      : "";
  }

  return (
    <Helmet>
      <style>
        {`        
         ${`.sidenav 
         {           
          color:${"white"};             
         }`}

        ${lightHoverStyle()}
        ${darkHoverStyle()}
        
      `}
      </style>
    </Helmet>
  );
};

export default SidenavTheme;
