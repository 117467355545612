import React from "react";
import { Redirect } from "react-router-dom";

const Incidents = React.lazy(() => import("./views/Incidents"));
const IncidentDetails = React.lazy(() =>
  import("./views/Incidents/IncidentDetails")
);
const Users = React.lazy(() => import("./views/Users/Users"));
const UserProfile = React.lazy(() =>
  import("./views/UsersProfile/UsersProfile")
);

export const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/incidents/unresolved" />,
  },
  {
    path: "/incidents/:incidentType",
    exact: true,
    component: Incidents,
  },
  {
    path: "/incidents/:incidentType/details/:id",
    exact: true,
    component: IncidentDetails,
  },
  {
    path: "/users",
    exact: true,
    component: Users,
  },
  {
    path: "/view-users/:id",
    exact: true,
    component: UserProfile,
  },
];
export default routes;
