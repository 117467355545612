import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import userTableReducer from "./userTableReducer";
import viewProfileReducer from "./viewProfileReducer";
import incidentsReducer from "./incidentsReducer";
import LayoutReducer from "./LayoutReducer";

const RootReducer = combineReducers({
  user: UserReducer,
  layout: LayoutReducer,
  userTable: userTableReducer,
  incidents: incidentsReducer,
  viewProfile: viewProfileReducer,
});

export default RootReducer;
