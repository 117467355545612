export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_ALL = "GET_ALL";
export const GET_INCIDENTS = "GET_INCIDENTS";
export const GET_INCIDENTS_DETAILS = "GET_INCIDENTS_DETAILS";
export const GET_PROFILE = "GET_PROFILE";
export const GET_IMAGES = "GET_IMAGES";
export const GET_COURSES = "GET_COURSES";
export const GET_ACTIVE = "GET_ACTIVE";
export const GET_HISTORICAL = "GET_HISTORICAL";
export const GET_BLOCKED = "GET_BLOCKED";
export const GET_REPORTED_AGAINST = "GET_REPORTED_AGAINST";
export const GET_INITIATED = "GET_INITIATED";
export const GET_UNIVERSITY_DOMAIN = "GET_UNIVERSITY_DOMAIN";
export const USER_CHAT = "USER_CHAT";
