import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  expandIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)",
  },
  collapseIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)",
  },
  "expansion-panel": {
    overflow: "hidden",
    transition: "max-height 0.3s cubic-bezier(0, 0, 0.2, 1)",
  },
});

class EgretVerticalNav extends Component {
  state = {
    collapsed: true,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.collapsed !== nextProps.collapsed) {
      if (nextProps.collapsed) {
        this.setState({
          collapsed: nextProps.collapsed,
        });
      }
    }
  }

  handleClick = (key) => {
    if (key === "Users") {
      this.setState({
        collapsed: !this.state.collapsed,
      });
    }
  };
  render() {
    return <div className="navigation"></div>;
  }
}

export default withStyles(styles)(EgretVerticalNav);
