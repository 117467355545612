import {
  GET_PROFILE,
  GET_IMAGES,
  GET_COURSES,
  GET_ACTIVE,
  GET_HISTORICAL,
  GET_BLOCKED,
  GET_REPORTED_AGAINST,
  USER_CHAT,
  GET_INITIATED,
  GET_UNIVERSITY_DOMAIN,
} from "../actions/actionTypes";

const initialState = {
  getUserProfile: {},
  getUserImages: [],
  getUserCourses: [],
  getActiveMatches: [],
  getHistoricalMatches: [],
  getBlockedMatches: [],
  getReportedAgainst: [],
  getReportedInitiated: [],
  getUniversityDomain: [],
  getChat: [],
};

const viewProfileReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE: {
      return {
        ...state,
        getUserProfile: action.payload,
      };
    }
    case GET_IMAGES: {
      return {
        ...state,
        getUserImages: action.payload,
      };
    }
    case GET_COURSES: {
      return {
        ...state,
        getUserCourses: action.payload,
      };
    }
    case GET_ACTIVE: {
      return {
        ...state,
        getActiveMatches: action.payload,
      };
    }
    case GET_HISTORICAL: {
      return {
        ...state,
        getHistoricalMatches: action.payload,
      };
    }
    case GET_BLOCKED: {
      return {
        ...state,
        getBlockedMatches: action.payload,
      };
    }
    case GET_REPORTED_AGAINST: {
      return {
        ...state,
        getReportedAgainst: action.payload,
      };
    }
    case GET_INITIATED: {
      return {
        ...state,
        getReportedInitiated: action.payload,
      };
    }
    case USER_CHAT: {
      return {
        ...state,
        getChat: action.payload,
      };
    }
    case GET_UNIVERSITY_DOMAIN: {
      return {
        ...state,
        getUniversityDomain: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default viewProfileReducer;
