import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setLayoutSettings } from "app/redux/actions/LayoutActions";
import { withStyles } from "@material-ui/core";
import Scrollbar from "react-perfect-scrollbar";
import { isMdScreen, classList } from "utils";
import Layout1Topbar from "./EgretLayout/waraperComponent/Layout1Topbar";
import Layout1Sidenav from "./EgretLayout/waraperComponent/Layout1Sidenav";
import SecondarySidebar from "./EgretLayout/SharedCompoents/SecondarySidebar";
import AppContext from "app/appContext";
import Content from "./EgretLayout/waraperComponent/Content";

const styles = (theme) => {
  return {
    layout: {
      backgroundColor: theme.palette.background.default,
    },
  };
};

class Home extends Component {
  UNSAFE_componentWillMount() {
    if (isMdScreen()) {
      this.updateSidebarMode({ mode: "close" });
    }
  }

  updateSidebarMode = (sidebarSettings) => {
    let { settings, setLayoutSettings } = this.props;
    setLayoutSettings({
      ...settings,
      layout1Settings: {
        ...settings.layout1Settings,
        leftSidebar: {
          ...settings.layout1Settings.leftSidebar,
          ...sidebarSettings,
        },
      },
    });
  };

  render() {
    let { settings, classes, theme } = this.props;

    let { layout1Settings } = settings;

    let layoutClasses = {
      [classes.layout]: true,
      [`${settings.activeLayout} sidenav-${layout1Settings.leftSidebar.mode} theme-${theme.palette.type} flex`]: true,
      "topbar-fixed": layout1Settings.topbar.fixed,
    };
    return (
      <AppContext.Consumer>
        {() => (
          <div className={classList(layoutClasses)}>
            <Layout1Sidenav />
            <div className="content-wrap position-relative">
              <Scrollbar className="scrollable-content">
                <Layout1Topbar />
                <div className="content">
                  <Content />
                </div>
                <div className="my-auto" />
              </Scrollbar>
            </div>
            <SecondarySidebar />
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

Home.propTypes = {
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  setLayoutSettings: PropTypes.func.isRequired,
  settings: state.layout.settings,
});

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, { setLayoutSettings })(Home)
);
