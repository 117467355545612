import { gql } from "apollo-boost";

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($UpdateInput: UpdateInput) {
    updateUserProfile(UpdateInput: $UpdateInput)
  }
`;
export const REMOVE_COURSES = gql`
  mutation removeCourses($id: ID, $userId: ID) {
    removeCourses(id: $id, userId: $userId)
  }
`;
export const ACTIVE_COURSES = gql`
  mutation activateCourse($id: ID, $userId: ID) {
    activateCourse(id: $id, userId: $userId)
  }
`;
export const HIDE_IMAGES = gql`
  mutation hiddenUserImages($id: ID, $img: String) {
    hiddenUserImages(id: $id, img: $img)
  }
`;
export const ACTIVE_IMAGES = gql`
  mutation undoUserImages($id: ID, $img: String) {
    undoUserImages(id: $id, img: $img)
  }
`;
export const REMOVE_MATCHES = gql`
  mutation removeMatches($id: ID, $friendId: ID) {
    removeMatches(id: $id, friendId: $friendId)
  }
`;
export const ACTIVATE_MATCHES = gql`
  mutation activateMatches($id: ID, $friendId: ID) {
    activateMatches(id: $id, friendId: $friendId)
  }
`;
export const BLOCK_MATCHES = gql`
  mutation blockMatches($id: ID, $friendId: ID) {
    blockMatches(id: $id, friendId: $friendId)
  }
`;
