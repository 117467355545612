import React, { Component, Suspense } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "../history";
import Login from "./views/Authentication/LogIn";
import ForgotPassword from "./views/Authentication/ForgotPassword";
import NotFound from "./views/Authentication/NotFound";
import Home from "./Home";
const SecretRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated =
    localStorage.getItem("auth_Token") === null ? false : true;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              //   state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class Routes extends Component {
  render() {
    return (
      // <Suspense fallback={<Loader />}>
      <Suspense fallback>
        <Router history={history}>
          <Switch>
            <Route
              exact
              path="/login"
              component={() => <Login history={history} />}
            />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <SecretRoute path="/" component={Home} />
            <Route
              render={() => (
                // <Suspense fallback={<Loader />}>
                //   <NotFound history={history} />
                // </Suspense>
                <Suspense fallback>
                  <NotFound history={history} />
                </Suspense>
              )}
            />
          </Switch>
        </Router>
      </Suspense>
    );
  }
}
export default Routes;
